export enum FilledStatus {
  full = 'full',
  half = 'half',
  empty = 'empty',
}

export enum ScenarioWizardStep {
  general = 'general',
  timeHorizon = 'timeHorizon',
  topology = 'topology',
  loads = 'loads',
  equipment = 'equipment',
  commodities = 'commodities',
  meters = 'meters',
  mobility = 'mobility',
  reserves = 'reserves',
  netting = 'netting',
  regulation = 'regulation',
  optimizationCockpit = 'optimizationCockpit',
  launch = 'launch',
}

export enum ScenarioWizardStepForDetail {
  line = ScenarioWizardStep.topology,
  load = ScenarioWizardStep.loads,
  equipment = ScenarioWizardStep.equipment,
  operationalCost = ScenarioWizardStep.commodities,
  energyGridConnection = ScenarioWizardStep.commodities,
  egcEmission = ScenarioWizardStep.commodities,
  marketLimits = ScenarioWizardStep.commodities,
  fuel = ScenarioWizardStep.commodities,
  meterDefinition = ScenarioWizardStep.meters,
  meterCostAndBound = ScenarioWizardStep.meters,
  meterTechnologiesAndMarkets = ScenarioWizardStep.meters,
  netting = ScenarioWizardStep.netting,
  taxAndSubsidy = ScenarioWizardStep.netting,
  route = ScenarioWizardStep.mobility,
  vehiclesDispatch = ScenarioWizardStep.mobility,
  stationVehicleAssociation = ScenarioWizardStep.mobility,
  metering = ScenarioWizardStep.regulation,
  // shortCircuitEquipment = ScenarioWizardStep.reserves,
}

export interface WizardStepCriteria {
  readonly removeMobility: boolean;
  readonly removeNetting: boolean;
  readonly removeReserves: boolean;
  readonly removeTopology: boolean;
}
