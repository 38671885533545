import { Observable } from 'rxjs';

import { InjectionToken } from '@angular/core';

import {
  EnergyGridConnection,
  EnergyGridConnectionsEmissions,
  EnergyGridLimit,
  Equipment,
  Load,
  Metering,
  Netting,
  Scenario,
  StationVehicleAssoc,
  TaxAndSubsidies,
  VehiclesDispatch,
} from '../models';

type AffectedMsgParam = unknown[] | [Scenario, string[]];

export interface HasScenarioVariation {
  readonly scenarioVariation?: string;
}

export interface Affected {
  readonly name: string;
  readonly category: AffectedCategory;
}

export enum AffectedCategory {
  equip = 'Equipment',
  circuitEquips = 'Short Circuits - Equipment',
  commEGCs = 'Commodities - Energy Grid Connections',
  commEGCsEmissions = 'Commodities - Energy Grid Connections Emissions',
  commLimits = 'Commodities - Market Limits',
  loads = 'Loads',
  vehiclesDispatch = 'Mobility - Vehicles Dispatch',
  stationVehicleAssoc = 'Mobility - Station/Vehicle Association',
  regulation = 'Regulation',
  netting = 'Netting',
  taxAndSubsidies = 'Netting - Tax & Subsidies',
}

export const VARIATION_CASCADER = new InjectionToken<VariationCascader>(
  'variation-cascader',
);

export interface VariationCascader {
  generateAffectedMessage(param: AffectedMsgParam): string;
  cascadeScenarioVariation(scenario: Scenario, deleted: string[]): Scenario;
  showDeletedDialog(deleted: string[], message: string): Observable<unknown>;
}

export interface DeletedEntityAffector {
  touchEGCs(scenario: Scenario, deleted: string[]): Affected[];
  touchEGCEmissions(scenario: Scenario, deleted: string[]): Affected[];
  touchLimits(scenario: Scenario, deleted: string[]): Affected[];
  touchLoads(scenario: Scenario, deleted: string[]): Affected[];
  touchEquipments(scenario: Scenario, deleted: string[]): Affected[];
  touchVehiclesDispatch(scenario: Scenario, deleted: string[]): Affected[];
  touchStationVehicleAssoc(scenario: Scenario, deleted: string[]): Affected[];
  touchCircuitEquipments(scenario: Scenario, deleted: string[]): Affected[];
  touchMarketReserves(scenario: Scenario, deleted: string[]): Affected[];
  touchEquipmentReserves(scenario: Scenario, deleted: string[]): Affected[];
  touchNetting(scenario: Scenario, deleted: string[]): Affected[];
  touchTaxAndSubsidies(scenario: Scenario, deleted: string[]): Affected[];
  touchMeterings(scenario: Scenario, deleted: string[]): Affected[];
  getAffectedEGCs(
    scenario: Scenario,
    deleted: string[],
  ): EnergyGridConnection[];
  getAffectedEGCEmissions(
    scenario: Scenario,
    deleted: string[],
  ): EnergyGridConnectionsEmissions[];
  getAffectedLimits(scenario: Scenario, deleted: string[]): EnergyGridLimit[];
  getAffectedLoads(scenario: Scenario, deleted: string[]): Load[];
  getAffectedEquipments(scenario: Scenario, deleted: string[]): Equipment[];
  getAffectedVehiclesDispatch(
    scenario: Scenario,
    deleted: string[],
  ): VehiclesDispatch[];
  getAffectedStationVehicleAssoc(
    scenario: Scenario,
    deleted: string[],
  ): StationVehicleAssoc[];
  getAffectedNetting(scenario: Scenario, deleted: string[]): Netting[];
  getAffectedTaxAndSubsidies(
    scenario: Scenario,
    deleted: string[],
  ): TaxAndSubsidies[];
  getAffectedMeterings(scenario: Scenario, deleted: string[]): Metering[];
}
