import { Coerce } from 'prosumer-app/core/utils/coercion.util';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import {
  DetailEntity,
  NameValue,
  ScenarioDetailState,
} from './scenario-detail.state';
import { ScenarioDetailStore } from './scenario-detail.store';

@Injectable({
  providedIn: 'root',
})
export abstract class ScenarioDetailQuery<
  T extends DetailEntity,
> extends QueryEntity<ScenarioDetailState<T>> {
  constructor(
    store: ScenarioDetailStore<T>,
    readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  getAllForActiveScenario(): T[] {
    return this.onlyForActiveScenario(this.getAll());
  }

  selectAllForActiveScenario(): Observable<T[]> {
    return this.selectAll().pipe(
      map((entities) => this.onlyForActiveScenario(entities)),
    );
  }

  private onlyForActiveScenario(entities: T[]): T[] {
    return entities.filter((entity) => this.isFromActiveScenario(entity));
  }

  private isFromActiveScenario(entity: T): boolean {
    return entity.scenarioUuid === this.keeper.getActiveScenario();
  }

  getActiveOptions(filteringKey: string = null): NameValue[] {
    return this.mapToNameValue(this.getAllForActiveScenario(), filteringKey);
  }

  mapToNameValue(options: T[], filteringKey: string = null): NameValue[] {
    return Coerce.toArray(options).map((option: T) => ({
      value: this.getDisplayValue(option),
      name: this.getDisplayName(option),
      ...(filteringKey && {
        filteringValue: this.getFilteringValue(option, filteringKey),
      }),
    }));
  }

  getDisplayName(entity: T): string {
    return entity.id;
  }

  getDisplayValue(entity: T): string {
    return entity.id;
  }

  private getFilteringValue(entity: T, filteringKey: string) {
    return [].concat(entity[filteringKey]);
  }
}
