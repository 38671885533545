import { Coerce } from 'prosumer-core/utils';
import {
  ProfileBE,
  ProfileGroupBE,
  ReservesForm,
} from 'prosumer-scenario/models';

export class ReservesMapper {
  static mapToBackend = (reservesForm: ReservesForm) => ({});

  static mapToFrontend = (
    scenario: any,
    startYear?: number,
    endYear?: number,
  ): ReservesForm => ({});

  private static extractProfiles(profile: ProfileGroupBE): ProfileBE[] {
    return Coerce.toArray(Coerce.toObject(profile).profiles);
  }
}
